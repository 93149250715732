import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.article`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;

  .book-room-wrapper {
    padding: 0 3rem 3rem 3rem;
  }

  .form-field-title {
    margin: 0 0 0.7rem 0;
  }

  span {
    color: red;
  }

  input {
    margin: 0 0 2rem 0;
    width: 100%;
    max-width: 30.8rem;
    height: 2.4rem;
    border: var(--black) solid 1px;
    border-radius: 2px;
  }

  select {
    margin: 0 0 2rem 0;
    width: 100%;
    max-width: 30.8rem;
    height: 3.2rem;
    border: var(--black) solid 1px;
    border-radius: 2px;
  }

  input[type='date'] {
    margin-right: 0.8rem;
    width: 15rem;
    height: 3rem;
  }

  textarea {
    margin: 0 0 2rem 0;
    width: 100%;
    max-width: 30.8rem;
    height: 10rem;
    border: var(--black) solid 1px;
    border-radius: 2px;
  }

  button {
    padding: 0.7rem 1.9rem;
    color: var(--white);
    background-color: var(--yellow);
    border: solid var(--yellow);
    border-radius: 0.4rem;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0.07143em 0.07143em 0 rgb(0 0 0 / 20%);
    }
  }

  p {
    text-align: left;
  }
`

const BookRoomPage = () => {
  return (
    <Wrapper>
      <div className="book-room-wrapper">
        <h1>Забронировать номер</h1>
        <form
          name="Book a Room Form"
          method="POST"
          netlify="true"
          action="/thank-you-for-booking-a-room">
          <input type="hidden" name="form-name" value="Book a Room Form" />

          <div className="form-field-title">
            <label htmlFor="name">
              ФИО <span>*</span>
            </label>
          </div>
          <input type="text" name="name" />

          <div className="form-field-title">
            <label htmlFor="phone">
              Телефон <span>*</span>
            </label>
          </div>
          <input type="tel" name="phone" />

          <div className="form-field-title">
            <label htmlFor="email">
              Эл.почта <span>*</span>
            </label>
          </div>
          <input type="email" name="email" />

          <div className="form-field-title">
            <label htmlFor="persons">
              Количество взрослых<span>*</span>
            </label>
          </div>
          <input type="number" name="persons" />

          <div className="form-field-title">
            <label htmlFor="kids">
              Количество детей<span>*</span>
            </label>
          </div>
          <input type="text" name="kids" />

          <div className="form-field-title">
            <label htmlFor="age">
              Возраст детей<span>*</span>
            </label>
          </div>
          <input type="text" name="age" />

          <div className="form-field-title">
            <label htmlFor="dateFrom">
              Период проживания <span>*</span>
            </label>
          </div>
          <div className="row">
            <input type="date" name="dateFrom" />
            <input type="date" name="dateTo" />
          </div>

          <div className="form-field-title">
            <label htmlFor="category">
              Категория номера <span>*</span>
            </label>
          </div>
          <select name="category">
            <option value="эконом">Эконом</option>
            <option value="стандарт">Стандарт</option>
            <option value="двухъярусный (вид на озеро)">
              Двухъярусный (вид на озеро)
            </option>
            <option value="двухъярусный (вид на базу)">
              Двухъярусный (вид на базу)
            </option>
            <option value="юрта">Юрта</option>
          </select>

          <div className="form-field-title">
            <label htmlFor="message">Комментарий</label>
          </div>
          <textarea name="message" />
          <div>
            <button type="submit">Отправить</button>
          </div>
        </form>
      </div>
    </Wrapper>
  )
}

export default BookRoomPage
